<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          代理列表
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create()" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>

          <!--按钮组-->
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table
            show-select
            fixed-header
            dense
            height="70vh"
            v-model="table.selected"
            :headers="table.headers"
            :items="table.desserts"
            :single-select="table.singleSelect"
            :footer-props="table.footer"
            :server-items-length="table.itemsCount"
            :options.sync="table.pagination"
            :no-data-text="table.noDataText"
            :loading="table.loading"
            :loading-text="table.loadingText"
            item-key="id"
          >


            <template v-slot:item.status="{ item }">
              <h5>
                <b-badge variant="success" v-if="item.status == 1"
                  >正常
                </b-badge>
                <b-badge variant="primary" v-else-if="item.status == 2"
                  >待审核
                </b-badge>
                <b-badge variant="danger" v-else>禁用</b-badge>
              </h5>
            </template>

            <template v-slot:item.sort="{ item }">
              <span>
               {{ item.sort }}
              </span>
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="编辑"
                v-on:click="edit(item.id)"
              >
                <v-icon>la la-edit</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="审核"
                v-if="item.status == 2"
                v-on:click="audit(item)"
              >
                <v-icon>la la-gears</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="禁用"
                v-else-if="item.status == 1"
                v-on:click="changeStatus(item, 0)"
              >
                <v-icon>icon-xl la la-lock</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="启用"
                v-else-if="item.status == 0"
                v-on:click="changeStatus(item, 1)"
              >
                <v-icon>icon-xl la la-lock-open</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select
                    :options="filter.conditions"
                    v-model="filter.field"
                  ></b-form-select>
                </b-form-group>
                <label
                  class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3"
                  >:</label
                >
                <b-form-group class="mx-2">
                  <b-form-input
                    id="input-2"
                    v-model="filter.keyword"
                    placeholder="检索关键词"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select
                    :options="filter.statusOptions"
                    v-model="filter.status"
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-数据表单-->
    <b-modal
      ref="my-modal"
      size="lg"
      centered
      title="代理列表编辑"
      static="true"
    >
      <b-container fluid>
        <b-form
          id="agentForm"
          method="POST"
          class="form"
          novalidate="novalidate"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group label="代理名称:">
                <b-form-input
                  type="text"
                  name="agentName"
                  v-model="formModel.agentName"
                  placeholder="请输入agent..."
                ></b-form-input>
              </b-form-group>

              <b-form-group label="通知邮箱:">
                <b-form-input
                  type="text"
                  name="email"
                  v-model="formModel.email"
                  placeholder="请输入通知邮箱..."
                ></b-form-input>
              </b-form-group>

              <b-form-group label="QQ号码:">
                <b-form-input
                  type="text"
                  name="qq"
                  v-model="formModel.qq"
                  placeholder="请输入QQ号码..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="状态:">
                <v-radio-group class="mt-0" row  name="status" v-model="formModel.status" :mandatory="false">
                  <v-radio label="正常" :value="1"></v-radio>
                  <v-radio label="禁用" :value="0"></v-radio>
                </v-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="登录账号:">
                <b-form-input
                  type="text"
                  name="loginAccount"
                  v-model="formModel.loginAccount"
                  placeholder="请输入登录账号..."
                ></b-form-input>
              </b-form-group>

              <b-form-group label="手机号码:">
                <b-form-input
                  type="text"
                  name="telPhone"
                  v-model="formModel.telPhone"
                  placeholder="请输入手机号码..."
                ></b-form-input>
              </b-form-group>

              <b-form-group label="分红费率:">
                <b-form-input
                  type="text"
                  name="dividendRates"
                  v-model="formModel.dividendRates"
                  placeholder="请输入分红费率..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      <!--表格筛选-->
      <template v-slot:modal-footer>
        <b-button v-on:click="onClose" variant="danger">取消</b-button>
        <b-button v-on:click="onSubmit" variant="primary">保存</b-button>
      </template>
    </b-modal>
    <!--模态框-数据表单-->

  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {

      current: {
        supplierName: "",
        userId: null,
        loginAccount: "",
        contactPhone: "",
        supplierId: null,
      },
      filter: {
        conditions: [
          { value: null, text: "检索条件" },
          { value: "agentName", text: "代理名称" },
          { value: "loginAccount", text: "登录账号" },
          { value: "telPhone", text: "手机号码" },
          { value: "email", text: "电子邮箱" },
        ],
        statusOptions: [
          { value: null, text: "状态" },
          { value: 0, text: "禁用" },
          { value: 1, text: "正常" },
        ],
        field: null,
        keyword: "",
        status: null,
      },
      roleSelected: [],
      submitUrl: "",
      formModel: {
        id: "",
        agentName: "",
        loginAccount: "",
        avator: "",
        email: "",
        telPhone: "",
        qq: "",
        dividendRates: "",
        status: ""
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [
          { text: "代理名称", value: "agentName" },
          { text: "登录账号", value: "loginAccount" },
          { text: "代理头像", value: "avator" },
          { text: "通知邮箱", value: "email" },
          { text: "手机号码", value: "telPhone" },
          { text: "QQ号码", value: "qq" },
          { text: "分红费率", value: "dividendRates" },
          { text: "状态", value: "status" },
          { text: "创建时间", value: "createTime" },
          { text: "更新时间", value: "updateTime" },
          { text: "操作", value: "action" },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {},
        desserts: [],
      },
    };
  },
  mounted() {
    // Create a FormValidation instance
    let agentForm = KTUtil.getById("agentForm");
    this.fv = formValidation(agentForm, {
      fields: {
    
        agentName: {
          validators: {
            notEmpty: {
              message: "代理名称不能为空!",
            },
          },
        },
        loginAccount: {
          validators: {
            notEmpty: {
              message: "登录账号不能为空!",
            },
          },
        },
        avator: {
          validators: {
            notEmpty: {
              message: "代理头像不能为空!",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "通知邮箱不能为空!",
            },
          },
        },
        telPhone: {
          validators: {
            notEmpty: {
              message: "手机号码不能为空!",
            },
          },
        },
        qq: {
          validators: {
            notEmpty: {
              message: "QQ号码不能为空!",
            },
          },
        },
        dividendRates: {
          validators: {
            notEmpty: {
              message: "分红费率不能为空!",
            },
          },
        },
        status: {
          validators: {
            notEmpty: {
              message: "请选择状态!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

  },
  created() {},

  methods: {
    create() {
      this.submitUrl = "/api/agent/manage/create";
      this.formModel = {};

      this.fv.addField("loginAccount", {
        validators: {
          notEmpty: {
            message: "用户名不能为空!",
          },
          remote: {
            message: "此登录账号已存在,不可用!",
            method: "POST",
            url: "/api/user/exist",
          },
        },
      });
      this.$refs["my-modal"].show();
    },

    edit(id = undefined) {
      this.submitUrl = "/api/agent/manage/update";
      id = id == undefined ? this.getSelectedIds() : [id];
      if (id.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      this.fv.addField("loginAccount", {});
      this.fv.removeField("loginAccount");

      let that = this;
      console.info(id);
      ApiService.query("/api/agent/manage/query_by_id", {
        params: {
          'id': id[0],
        },
      }).then(({ data }) => {
        that.formModel = data.result;
      });

      this.$refs["my-modal"].show();
    },


    changeStatus(item, status) {
      if (item == null || item == undefined) {
        Swal.fire({
          title: "",
          text: "请选择要处理的数据行!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }
      this.current.id = item.id;
      this.current.userId = item.userId;
      this.submitAudit(status);
    },


    getData() {
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/agent/manage/query_list", {
        params: params,
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    },

    onClose() {
      this.$refs["my-modal"].hide();
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["my-modal"].hide();
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
    submitAudit(status) {
      let that = this;
      ApiService.post("/api/agent/manage/change_status", {
        id: that.current.id,
        userId: that.current.userId,
        status: status,
      }).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "提交成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
        } else {
          Swal.fire({
            title: "提交失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
  },

  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

